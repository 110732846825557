<template>
  <div>
    <div></div>
    <div>
      <el-card v-for="product in products" :key="product.ProductId" :body-style="{ padding: '0px' }">
        <img :src="product.PicUrl" class="image" />
        <div class="desc">
          <div class="other">
            <span><span class="dollar-sign">¥ </span>{{ product.MarketPrice }}</span>
            <span>1天前</span>
          </div>
          <div class="title">{{ product.Title }}</div>
        </div>
      </el-card>
    </div>
    <div>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="param.currentPage"
        :page-size="param.pageSize" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { submit } from "@/api/base";

export default {
  name: "purchaseMarket",
  mounted() {
    this.$nextTick(() => {
      this.handleGetProductList();
    });
  },
  data() {
    return {
      total: 0,
      products: [],
      param: {
        supplierID: "2563556665566",
        currentPage: 1,
        pageSize: 15,
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.param.currentPage = val;
      this.handleGetProductList();
    },
    async handleGetProductList() {
      const { data } = await submit(
        "/api/product/getPlatformProductList",
        this.param
      );
      this.total = data.total;
      this.products = data.datas;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  width: 218px;
  display: inline-block;
  margin-right: 10px;

  img {
    width: 218px;
    height: 218px;
    object-fit: contain;
  }

  .desc {
    width: 218px;
    height: 94px;
    padding: 10px 16px;
    box-sizing: border-box;

    .other {
      margin-bottom: 10px;
      position: relative;

      :first-child {
        color: #ff4200;
        font-size: 22px;
        font-weight: 500;

        .dollar-sign {
          font-size: 15px;
        }
      }

      :last-child {
        position: absolute;
        right: 0;
        top: 3px;
        color: #666666;
        font-size: 13px;
        font-weight: 400;
      }
    }

    .title {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
